import { graphql } from "gatsby"
import React from "react"
import Layout from "../layouts/Layout"
import SiteMetadata from "../components/SiteMetadata"
import ContactForm from "../components/ContactForm"
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'

const ContactPage = ({ data }) => (
  <Layout>
    <SiteMetadata title="אודות" description="Sample description" />
    <div className="bg-gray-100">
            <div className="container py-12 lg:pb-16">
                <div className="flex flex-wrap ">
                    <div className="w-full pb-8 md:pb-0 text-right text-xl">
                            <h1 className="text-3xl leading-tight font-semibold tracking-tight text-gray-900 sm:text-4xl ">
                            פרטי התקשרות
                            </h1>
                            <br/>
                            טלפון: <span className="hover:underline">
                            <a href="tel:0542158851"
                            onClick={() => {trackCustomEvent({category: "about_phonecall", action: "Click"})}}
                            >054-2158851</a></span>
                            <br/>
                            כתובת: יצחק בן-צבי 10, הוד השרון
                            <br/> 
                            דוא"ל: <span className="hover:underline">
                            <a href="mailto:yaronbaruh@gmail.com"
                            onClick={() => {trackCustomEvent({category: "about_mailto", action: "Click"})}}
                            >yaronbaruh@gmail.com</a></span>
                            <br/><br/>
                    </div>
                    <div className="w-full pb-8 md:pb-0 text-right">
                        <h1 className="text-3xl leading-tight font-semibold tracking-tight text-gray-900 sm:text-4xl ">
                        או השאירו פרטים
                        </h1>
                        <h2 className="text-xl leading-tight font-semibold tracking-tight text-blue-600 sm:text-2xl">
                        ואחזור אליכם בהקדם האפשרי.
                        </h2>
                        <br/>
                    </div>
                    <div className="flex-wrap pb-8 text-right lg:w-2/5 md:w-3/5 sm:w-4/5">
                    <ContactForm />
                    </div>
                </div>
          </div>
    </div>
  </Layout>
)

export default ContactPage

export const query = graphql`
  query {
    author: file(relativePath: { eq: "author.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, maxHeight: 480, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

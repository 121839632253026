// Customize this 'myform.js' script and add it to your JS bundle.
// Then import it with 'import MyForm from "./myform.js"'.
// Finally, add a <MyForm/> element whereever you wish to display the form.

import React from "react";
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'

export default class ContactForm extends React.Component {
  constructor(props) {
    super(props);
    this.submitForm = this.submitForm.bind(this);
    this.state = {
      status: ""
    };
  }

  render() {
    const { status } = this.state;
    return (
                <form className="w-full flex-wrap max-w-xl"
                    onSubmit={this.submitForm}
                    action="https://formspree.io/xzbjqdoo"
                    method="POST"
                >
                    <input placeholder="דואר אלקטרוני:" className="appearance-none w-full mb-5 px-3 py-3 ml-2 border border-gray-400 leading-snug rounded-md text-gray-900 bg-white placeholder-gray-600 focus:outline-none focus:shadow-outline focus:border-blue-300 transition duration-150 ease-in-out text-right"
                        type="email" name="email" />
                    <input placeholder="שם מלא:" className="appearance-none w-full mb-5 px-3 py-3 ml-2 border border-gray-400 leading-snug rounded-md text-gray-900 bg-white placeholder-gray-600 focus:outline-none focus:shadow-outline focus:border-blue-300 transition duration-150 ease-in-out text-right"
                        type="name" name="name" />
                    <input placeholder="טלפון:" className="appearance-none w-full mb-5 px-3 py-3 ml-2 border border-gray-400 leading-snug rounded-md text-gray-900 bg-white placeholder-gray-600 focus:outline-none focus:shadow-outline focus:border-blue-300 transition duration-150 ease-in-out text-right"
                        type="phone" name="phone" />
                    <textarea placeholder="הודעה:" rows="6" className="appearance-none w-full px-5 py-3 border border-gray-400 leading-snug rounded-md text-gray-900 bg-white placeholder-gray-600 focus:outline-none focus:shadow-outline focus:border-blue-300 transition duration-150 ease-in-out text-right"
                        type="text" name="message" />
                            {status === "SUCCESS" ? <p><b>הודעתכם נשלחה בהצלחה.</b></p> : <button className="inline-block  w-full px-5 py-5 mt-3 font-medium leading-snug border border-transparent text-base rounded-md text-white bg-gray-900 hover:bg-blue-600 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out">שליחה</button>}
                    {status === "ERROR" && <p><b>התרחשה שגיאה.</b></p>}
                </form>
    );
  }

  submitForm(ev) {
    ev.preventDefault();
    trackCustomEvent({category: "contactform_submit", action: "Click"})
    const form = ev.target;
    const data = new FormData(form);
    const xhr = new XMLHttpRequest();
    xhr.open(form.method, form.action);
    xhr.setRequestHeader("Accept", "application/json");
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return;
      if (xhr.status === 200) {
        form.reset();
        this.setState({ status: "SUCCESS" });
        trackCustomEvent({category: "contactform_submit", action: "success"})
      } else {
        this.setState({ status: "ERROR" });
        trackCustomEvent({category: "contactform_submit", action: "error"})
      }
    };
    xhr.send(data);
  }
}